.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #ffffff;
}

.Home .notes h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Home .notes p {
  color: #666;
}

.Home .lander div {
  padding-top: 15px;
}

.Home .lander div a:first-child {
  margin-right: 15px;
}

.rightSideImage{
  width: 250px;
  height: 250px;
  display: inline-block;
}
.leftSideText{
  color: black;
  display: inline-block;
}