.Project form {
  padding-bottom: 15px;

}


.Project form textarea {
  height: 300px;
  font-size: 24px;
}

#footer h5 {
  font-size: 24px;
}

#VideoAndSideStuff {
  float: left;
  width: 60%;
}

#ProjectWindow {
  height: 1893px;
}


#SideStuff {
  width: 30%;
  float: Left;
}

.SensorName {

  font-size:x-small;
}

.SensorValue {}

.ProjectNameBanner {
  text-align: center
}

.SensorInformation {
  border: solid #1b7c87 1px;
  border-radius: 3px;
}

.playProjectButton {
  -moz-box-shadow: 0px 10px 14px -7px #9fb4f2;
  -webkit-box-shadow: 0px 10px 14px -7px #9fb4f2;
  box-shadow: 0px 10px 14px -7px #9fb4f2;
  background-color: #7892c2;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 2px solid #4e6096;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Trebuchet MS;
  font-size: 20px;
  font-weight: bold;
  padding: 12px 15px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #283966;
}

.playProjectButton:hover {
  background-color: #476e9e;
}

.playProjectButton:active {
  position: relative;
  top: 1px;
}

.number-input input[type="number"] {
  -webkit-appearance: textfield;
    -moz-appearance: textfield;
          appearance: textfield;
}
.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.number-input {
  margin-bottom: 3rem;
}
.number-input button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  position: relative;
}
.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  height: 2px;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type=number] {
  text-align: center;
}
.number-input.number-input {
  border: 1px solid #ced4da;
  width: 10rem;
  border-radius: .25rem;
}
.number-input.number-input button {
  width: 2.6rem;
  height: .7rem;
}
.number-input.number-input button.minus {
  padding-left: 10px;
}
.number-input.number-input button:before,
.number-input.number-input button:after {
  width: .7rem;
  background-color: #495057;
}
.number-input.number-input input[type=number] {
  max-width: 4rem;
  padding: .5rem;
  border: 1px solid #ced4da;
  border-width: 0 1px;
  font-size: 1rem;
  height: 2rem;
  color: #495057;
}