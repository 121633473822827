@import url(https://fonts.googleapis.com/css?family=EB+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css?family=EB+Garamond&display=swap);
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #a3e7fc;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
  @keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
  
.NewProject form {
    padding-bottom: 15px;
  }
  
  .NewProject form textarea {
    height: 300px;
    font-size: 24px;
  }

  .rc-time-picker-input {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    height: 28px;
    cursor: text;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .rc-time-picker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5;
  }
.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #ffffff;
}

.Home .notes h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Home .notes p {
  color: #666;
}

.Home .lander div {
  padding-top: 15px;
}

.Home .lander div a:first-child {
  margin-right: 15px;
}

.rightSideImage{
  width: 250px;
  height: 250px;
  display: inline-block;
}
.leftSideText{
  color: black;
  display: inline-block;
}
.Nothing {
  margin-left: 260px;
}

.title{
  font-family: 'EB Garamond', serif;
}

.innerScreen {
  margin-left: 10px;
  margin-right: 10px;
}

.App .navbar-brand {
  font-weight: bold;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
}

.sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 75px);
  overflow: auto;
  width: 260px;
  z-index: 4;
  padding-bottom: 30px;
}

.sidebar .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.sidebar .logo {
  padding: 10px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  z-index: 4;
}

.sidebar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: #FFFFFF;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sidebar .logo a.logo-mini {
  float: left;
  text-align: center;
  width: 30px;
  margin-right: 15px;
}

.sidebar .logo a.logo-mini img {
  width: 40px;
  margin-left: -3px;
  display: block;
  margin-top: 2px;
}

.sidebar .logo a.logo-normal {
  display: block;
}

.sidebar .logo .logo-img {
  width: 34px;
  display: inline-block;
  height: 34px;
  margin-left: -2px;
  margin-top: -2px;
  margin-right: 10px;
  border-radius: 30px;
  text-align: center;
}

.sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}

.sidebar .logo-tim img {
  width: 60px;
  height: 60px;
}

.sidebar .nav {
  margin-top: 20px;
}

.sidebar .nav li>a {
  color: #FFFFFF;
  margin: 5px 15px;
  opacity: .86;
  border-radius: 4px;
}

.sidebar .nav li:hover>a,
.sidebar .nav li.open>a,
.sidebar .nav li.open>a:focus,
.sidebar .nav li.open>a:hover {
  background: rgba(255, 255, 255, 0.13);
  opacity: 1;
}

.sidebar .nav li.active>a {
  color: #FFFFFF;
  opacity: 1;
  background: rgba(255, 255, 255, 0.23);
}

.sidebar .nav p {
  margin: 0;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.sidebar .nav .caret {
  top: 24px;
  position: absolute;
  right: 15px;
}

.sidebar .nav i {
  font-size: 28px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

.sidebar .logo,
body>.navbar-collapse .logo {
  padding: 10px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar .logo p,
body>.navbar-collapse .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: #FFFFFF;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sidebar .logo .simple-text,
body>.navbar-collapse .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 30px;
}

.sidebar .logo-tim,
body>.navbar-collapse .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}

.sidebar .logo-tim img,
body>.navbar-collapse .logo-tim img {
  width: 60px;
  height: 60px;
}

.sidebar:after,
.sidebar:before,
body>.navbar-collapse:after,
body>.navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.sidebar:before,
body>.navbar-collapse:before {
  opacity: .33;
  background: #000000;
}

.sidebar:after,
body>.navbar-collapse:after {
  background: #282828;
  background: linear-gradient(to bottom, #282828 0%, #111 100%);
  background-size: 150% 150%;
  z-index: 3;
  opacity: 1;
}

.sidebar[data-image]:after,
.sidebar.has-image:after,
body>.navbar-collapse[data-image]:after,
body>.navbar-collapse.has-image:after {
  opacity: .77;
}

.sidebar[data-color="blue"]:after,
body>.navbar-collapse[data-color="blue"]:after {
  background: #1b8dff;
  background: linear-gradient(to bottom, #1b8dff 0%, #2632c1 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="azure"]:after,
body>.navbar-collapse[data-color="azure"]:after {
  background: #1DC7EA;
  background: linear-gradient(to bottom, #1DC7EA 0%, #4091ff 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="green"]:after,
body>.navbar-collapse[data-color="green"]:after {
  background: #87CB16;
  background: linear-gradient(to bottom, #87CB16 0%, #6dc030 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="orange"]:after,
body>.navbar-collapse[data-color="orange"]:after {
  background: #FFA534;
  background: linear-gradient(to bottom, #FFA534 0%, #ff5221 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="red"]:after,
body>.navbar-collapse[data-color="red"]:after {
  background: #FB404B;
  background: linear-gradient(to bottom, #FB404B 0%, #bb0502 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="purple"]:after,
body>.navbar-collapse[data-color="purple"]:after {
  background: #9368E9;
  background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%);
  background-size: 150% 150%;
}

.navbar {
  border: 0;
  font-size: 16px;
  border-radius: 0;
}

.navbar .navbar-brand {
  font-weight: 400;
  margin: 5px 0px;
  padding: 15px 15px;
  font-size: 20px;
}

.navbar .navbar-nav>li>a {
  padding: 10px 15px;
  margin: 10px 3px;
  position: relative;
}

.navbar .navbar-nav>li>a.btn {
  margin: 15px 3px;
  padding: 8px 16px;
}

.navbar .navbar-nav>li>a.btn-round {
  margin: 16px 3px;
}

.navbar .navbar-nav>li>a [class^="fa"] {
  font-size: 19px;
  position: relative;
  line-height: 16px;
  top: 1px;
}

.navbar .navbar-nav .notification {
  position: absolute;
  background-color: #FB404B;
  text-align: center;
  border-radius: 10px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 18px;
  top: 0px;
  left: 7px;
}

.navbar .btn {
  margin: 15px 3px;
  font-size: 14px;
}

.navbar .btn-simple {
  font-size: 16px;
}

.navbar.fixed {
  width: calc(100%);
  right: 0;
  left: auto;
  border-radius: 0;
}

.navbar-nav>li>.dropdown-menu {
  border-radius: 10px;
  margin-top: -5px;
}

.navbar-transparent .navbar-brand,
[class*="navbar-ct"] .navbar-brand {
  color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.navbar-transparent .navbar-brand:focus,
.navbar-transparent .navbar-brand:hover,
[class*="navbar-ct"] .navbar-brand:focus,
[class*="navbar-ct"] .navbar-brand:hover {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .navbar-nav>li>a:not(.btn),
[class*="navbar-ct"] .navbar-nav>li>a:not(.btn) {
  color: #FFFFFF;
  border-color: #FFFFFF;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.navbar-transparent .navbar-nav>.active>a:not(.btn),
.navbar-transparent .navbar-nav>.active>a:hover:not(.btn),
.navbar-transparent .navbar-nav>.active>a:focus:not(.btn),
.navbar-transparent .navbar-nav>li>a:hover:not(.btn),
.navbar-transparent .navbar-nav>li>a:focus:not(.btn),
[class*="navbar-ct"] .navbar-nav>.active>a:not(.btn),
[class*="navbar-ct"] .navbar-nav>.active>a:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav>.active>a:focus:not(.btn),
[class*="navbar-ct"] .navbar-nav>li>a:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav>li>a:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .navbar-nav .nav>li>a.btn:hover,
[class*="navbar-ct"] .navbar-nav .nav>li>a.btn:hover {
  background-color: transparent;
}

.navbar-transparent .navbar-nav>.dropdown>a .caret,
.navbar-transparent .navbar-nav>.dropdown>a:hover .caret,
.navbar-transparent .navbar-nav>.dropdown>a:focus .caret,
[class*="navbar-ct"] .navbar-nav>.dropdown>a .caret,
[class*="navbar-ct"] .navbar-nav>.dropdown>a:hover .caret,
[class*="navbar-ct"] .navbar-nav>.dropdown>a:focus .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF;
}

.navbar-transparent .navbar-nav>.open>a,
.navbar-transparent .navbar-nav>.open>a:hover,
.navbar-transparent .navbar-nav>.open>a:focus,
[class*="navbar-ct"] .navbar-nav>.open>a,
[class*="navbar-ct"] .navbar-nav>.open>a:hover,
[class*="navbar-ct"] .navbar-nav>.open>a:focus {
  background-color: transparent;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .btn-default,
[class*="navbar-ct"] .btn-default {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.navbar-transparent .btn-default.btn-fill,
[class*="navbar-ct"] .btn-default.btn-fill {
  color: #9A9A9A;
  background-color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default,
[class*="navbar-ct"] .btn-default.btn-fill:hover,
[class*="navbar-ct"] .btn-default.btn-fill:focus,
[class*="navbar-ct"] .btn-default.btn-fill:active,
[class*="navbar-ct"] .btn-default.btn-fill.active,
[class*="navbar-ct"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-open .nav .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF;
}

.navbar-default {
  background-color: rgba(255, 255, 255, 0.96);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.navbar-default .navbar-nav>li>a:not(.btn) {
  color: #9A9A9A;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:not(.btn):hover,
.navbar-default .navbar-nav>.active>a:not(.btn):focus,
.navbar-default .navbar-nav>li>a:not(.btn):hover,
.navbar-default .navbar-nav>li>a:not(.btn):focus {
  background-color: transparent;
  border-radius: 3px;
  color: #1DC7EA;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-default .navbar-nav>.dropdown>a:hover .caret,
.navbar-default .navbar-nav>.dropdown>a:focus .caret {
  border-bottom-color: #1DC7EA;
  border-top-color: #1DC7EA;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
  background-color: transparent;
  color: #1DC7EA;
}

.navbar-default .navbar-nav .navbar-toggle:hover,
.navbar-default .navbar-nav .navbar-toggle:focus {
  background-color: transparent;
}

.navbar-default:not(.navbar-transparent) .btn-default:hover {
  color: #1DC7EA;
  border-color: #1DC7EA;
}

.navbar-default:not(.navbar-transparent) .btn-neutral,
.navbar-default:not(.navbar-transparent) .btn-neutral:hover,
.navbar-default:not(.navbar-transparent) .btn-neutral:active {
  color: #9A9A9A;
}

/*      Navbar with icons            */
.navbar-icons.navbar .navbar-brand {
  margin-top: 12px;
  margin-bottom: 12px;
}

.navbar-icons .navbar-nav>li>a {
  text-align: center;
  padding: 6px 15px;
  margin: 6px 3px;
}

.navbar-icons .navbar-nav [class^="pe"] {
  font-size: 30px;
  position: relative;
}

.navbar-icons .navbar-nav p {
  margin: 3px 0 0;
}

.navbar-form {
  box-shadow: none;
}

.navbar-form .form-control {
  border-radius: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 22px;
  font-size: 16px;
  line-height: 1.5;
  color: #E3E3E3;
}

.navbar-transparent .navbar-form .form-control,
[class*="navbar-ct"] .navbar-form .form-control {
  color: #FFFFFF;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.navbar-ct-blue {
  background-color: #4ea7ff;
}

.navbar-ct-azure {
  background-color: #63d8f1;
}

.navbar-ct-green {
  background-color: #a1e82c;
}

.navbar-ct-orange {
  background-color: #ffbc67;
}

.navbar-ct-red {
  background-color: #fc727a;
}

.navbar-transparent {
  padding-top: 15px;
  background-color: transparent;
  border-bottom: 1px solid transparent;
}

.navbar-toggle {
  margin-top: 19px;
  margin-bottom: 19px;
  border: 0;
}

.navbar-toggle .icon-bar {
  background-color: #FFFFFF;
}

.navbar-toggle .navbar-collapse,
.navbar-toggle .navbar-form {
  border-color: transparent;
}

.navbar-toggle.navbar-default .navbar-toggle:hover,
.navbar-toggle.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

.card {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #FFFFFF;
  margin-bottom: 30px;
}

.card .image {
  width: 100%;
  overflow: hidden;
  height: 260px;
  border-radius: 4px 4px 0 0;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card .image img {
  width: 100%;
}

.card .filter {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.68);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  filter: alpha(opacity=0);
}

.card .filter .btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.card:hover .filter {
  opacity: 1;
  filter: alpha(opacity=100);
}

.card .btn-hover {
  opacity: 0;
  filter: alpha(opacity=0);
}

.card:hover .btn-hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.card .content {
  padding: 15px 15px 10px 15px;
}

.card .header {
  padding: 15px 15px 0;
}

.card .category,
.card label {
  font-size: 14px;
  font-weight: 400;
  color: #9A9A9A;
  margin-bottom: 0px;
}

.card .category i,
.card label i {
  font-size: 16px;
}

.card label {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.card .title {
  margin: 0;
  color: #333333;
  font-weight: 300;
}

.card .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.card .description {
  font-size: 14px;
  color: #333;
}

.card .footer {
  padding: 0;
  background-color: transparent;
  line-height: 30px;
}

.card .footer .legend {
  padding: 5px 0;
}

.card .footer hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.card .stats {
  color: #a9a9a9;
}

.card .footer div {
  display: inline-block;
}

.card .author {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.card .author i {
  font-size: 14px;
}

.card h6 {
  font-size: 12px;
  margin: 0;
}

.card.card-separator:after {
  height: 100%;
  right: -15px;
  top: 0;
  width: 1px;
  background-color: #DDDDDD;
  content: "";
  position: absolute;
}

.card .ct-chart {
  margin: 30px 0 30px;
  height: 245px;
}

.card .table tbody td:first-child,
.card .table thead th:first-child {
  padding-left: 15px;
}

.card .table tbody td:last-child,
.card .table thead th:last-child {
  padding-right: 15px;
}

.card .alert {
  border-radius: 4px;
  position: relative;
}

.card .alert.alert-with-icon {
  padding-left: 65px;
}

.card-user .image {
  height: 110px;
}

.card-user .image-plain {
  height: 0;
  margin-top: 110px;
}

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -70px;
}

.card-user .avatar {
  width: 124px;
  height: 124px;
  border: 5px solid #FFFFFF;
  position: relative;
  margin-bottom: 15px;
}

.card-user .avatar.border-gray {
  border-color: #EEEEEE;
}

.card-user .title {
  line-height: 24px;
}

.card-user .content {
  min-height: 240px;
}

.card-user .footer,
.card-price .footer {
  padding: 5px 15px 10px;
}

.card-user hr,
.card-price hr {
  margin: 5px 15px;
}

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.card-plain .image {
  border-radius: 4px;
}

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;
}

.card-stats .icon-big i {
  font-weight: bold;
  line-height: 59px;
}

.card-stats .numbers {
  font-size: 2em;
  text-align: right;
}

.card-stats .numbers p {
  margin: 0;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
  }
  
@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
@media all and (min-width: 480px) {
    .ResetPassword {
      padding: 60px 0;
    }
  
    .ResetPassword form {
      margin: 0 auto;
      max-width: 320px;
    }
  
    .ResetPassword .success {
      max-width: 400px;
    }
  }
  
  .ResetPassword .success {
    margin: 0 auto;
    text-align: center;
  }
  .ResetPassword .success .glyphicon {
    color: grey;
    font-size: 30px;
    margin-bottom: 30px;
  }

@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }
  
.Standards form {
  padding-bottom: 15px;
}

.Standards form textarea {
  height: 300px;
  font-size: 24px;
}

.pageTitle { 
  font-family: 'EB Garamond', serif;
} 

.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}
.Project form {
  padding-bottom: 15px;

}


.Project form textarea {
  height: 300px;
  font-size: 24px;
}

#footer h5 {
  font-size: 24px;
}

#VideoAndSideStuff {
  float: left;
  width: 60%;
}

#ProjectWindow {
  height: 1893px;
}


#SideStuff {
  width: 30%;
  float: Left;
}

.SensorName {

  font-size:x-small;
}

.SensorValue {}

.ProjectNameBanner {
  text-align: center
}

.SensorInformation {
  border: solid #1b7c87 1px;
  border-radius: 3px;
}

.playProjectButton {
  box-shadow: 0px 10px 14px -7px #9fb4f2;
  background-color: #7892c2;
  border-radius: 3px;
  border: 2px solid #4e6096;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Trebuchet MS;
  font-size: 20px;
  font-weight: bold;
  padding: 12px 15px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #283966;
}

.playProjectButton:hover {
  background-color: #476e9e;
}

.playProjectButton:active {
  position: relative;
  top: 1px;
}

.number-input input[type="number"] {
  -webkit-appearance: textfield;
          appearance: textfield;
}
.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.number-input {
  margin-bottom: 3rem;
}
.number-input button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  position: relative;
}
.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  height: 2px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type=number] {
  text-align: center;
}
.number-input.number-input {
  border: 1px solid #ced4da;
  width: 10rem;
  border-radius: .25rem;
}
.number-input.number-input button {
  width: 2.6rem;
  height: .7rem;
}
.number-input.number-input button.minus {
  padding-left: 10px;
}
.number-input.number-input button:before,
.number-input.number-input button:after {
  width: .7rem;
  background-color: #495057;
}
.number-input.number-input input[type=number] {
  max-width: 4rem;
  padding: .5rem;
  border: 1px solid #ced4da;
  border-width: 0 1px;
  font-size: 1rem;
  height: 2rem;
  color: #495057;
}
